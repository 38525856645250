import React from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import FaqsNavBar from '../../components/FaqsNavBar'

const PediSpaChairPage = () => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  const toggleAccordion = React.useCallback(
    (e, titleProps) => {
      const { index } = titleProps
      const newIndex = activeIndex === index ? -1 : index
      setActiveIndex(newIndex)
    },
    [activeIndex]
  )

  return (
    <>
      <Layout>
        <SEO
          title="Pedicure Spa Chair FAQ"
          description="Do you have a question regarding our pedicure spa chair? You might find your answer from most frequently questions asked by other customers. If not, drop us a line. We will answer your question as soon as possible."
          meta={[
            {
              name: 'keywords',
              content:
                'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
            }
          ]}
        />
        <FaqsNavBar />
        <div className="container py-4 responsive-fh animated fadeInUp slow">
          <h2 className="text-center">Pedi-Spa Chair Questions</h2>
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong> What are the dimensions of the pedi-spa chair?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                Please refer to the Novo Pedi-Spa Chair Specifications for dimensions. If you need
                assistance, please{' '}
                <AniLink paintDrip duration={1.62} hex="#ffffff" to="/contact-us">
                  contact us
                </AniLink>
                .
              </p>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 1} index={1} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>
                  {' '}
                  What is the difference between your pedicure jet system and the rest on the
                  market?
                </strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                Traditional pedicure jet systems are reusable and need to cleaned and sanitized
                after each service, and if not done properly, could result in cross-contamination
                and infection. Contégo pedi-spa chairs have a disposable foot spa system called
                Disposable Pedicure Jet™, that helps protect each and every client from
                cross-contamination. Pedicure jet tubes are embedded into the liner to provide the
                foot massage. After the service is complete, both the liner and pedicure jet tubes
                are disposed of, and every NEW service begins with a NEW Disposable Pedicure Jet™.
                Not only do you save time from cleaning reusable jets, but you also save money on
                all the costly chemicals you would have to use!
              </p>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 2} index={2} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong> What are the cleaning procedures for the Novo pedi-spa chair?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <p>
                Please review laws in your area for cleaning procedures. Contégo pedi-spa chairs are
                compliant with the{' '}
                <a
                  href="https://www.barbercosmo.ca.gov/laws_regs/art12.shtml#a980_4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Board of Barbering and Cosmetology Health and Safety Regulations: Title
                  16, Division 9, Section 980.4 Disposable Basin or Tub Liners
                </a>
                , which states:
              </p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong> What is included with the purchase of the pedi-spa chair?</strong>
              </p>{' '}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <p>
                For every pedi-spa chair that you purchase, you will also receive a Pedi Stool. With
                each order, you will also receive a Marketing Kit, that will assist in informing
                clients about your pedi-spa chair with a Disposable Pedicure Jet™ system to help
                keep them safe. The Marketing Kit includes: a beautiful 24x36” Salon Poster, 8.5x11”
                laminated Handouts customized with your salon name and/or logo, and (for California
                residents) an 11x17” Poster on the official California Board of Barbering and
                Cosmetology Health and Safety Regulations.
              </p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong> Do you offer a warranty on the spa chair?</strong>
              </p>{' '}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <p>
                We offer a 1-Year Warranty on the pedi-spa chair, and a 3-Year Warranty on the
                pedicure jet pump. Please{' '}
                <AniLink paintDrip duration={1.62} hex="#ffffff" to="/contact-us">
                  contact us
                </AniLink>{' '}
                for details.
              </p>
            </Accordion.Content>
          </Accordion>
        </div>
      </Layout>
    </>
  )
}

export default PediSpaChairPage
